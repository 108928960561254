<template>
  <a-card style="width: 100%;margin-bottom: 10px;position: relative" >
    <p style="text-align: center;font-weight: bold;font-size: 24px">第{{customInfo.serialNumber}}集</p>
    <video :ref="'vueRef' + customInfo.id" id="vueRef" :src="customInfo.videoUrl || ''" style="width:100%;height:150px;" controls></video>
<!--    <p style="margin-top: 10px">中文字幕:-->
<!--      <gatherUploadBtn :customInfo="customInfo" :customIndex="customIndex" :customType="1"  @change="change"></gatherUploadBtn>-->
<!--    </p>-->
<!--    <p>英文字幕:-->
<!--      <gatherUploadBtn :customInfo="customInfo" :customIndex="customIndex" :customType="2" @change="change"></gatherUploadBtn>-->
<!--    </p>-->
    <a-checkbox @change="onChange" :checked="checked" style="position: absolute;right: 5px;top: 5px">
    </a-checkbox>
  </a-card>
</template>

<script>
import {updateGather} from "@/api/video/gather";
import gatherUploadBtn from "./gatherUploadBtn";
export default {
  name: "gatherUpload",
  components:{
    gatherUploadBtn
  },
  props:{
    customInfo:{
      type:Object,
    },
    customIndex:{
      type:Number
    },
    checked:{
      type:Boolean
    }
  },
  data() {
    return {
    }
  },
  methods:{
    change() {
      this.$emit("change")
    },
    onChange(e) {
      console.log(e.target.checked);
      this.$emit("selectOption",{
        checked:e.target.checked,
        id:this.customInfo.id,
        data:this.customInfo
      })
    }
  }
}
</script>

<style scoped>

</style>